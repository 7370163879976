import { AppProps } from "next/app";
import React, { useEffect } from "react";
import Head from "next/head";
import { GlobalStyles } from "../styles/global-styles";
import { LanguageOption } from "@project/shared";
import TagManager from "react-gtm-module";

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTAG });
  }, []);
  return (
    <>
      <Head>
        <meta
          name={"google-site-verification"}
          content={"Tq_zDN8DRe16xDK9OYI2ID2c75IxJ0RBtvrKrGAKviY"}
        />
        <title>{"ぐるりん日野ナビ"}</title>
        <meta
          name={"viewport"}
          content={
            "width=device-width, initial-scale=1, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"
          }
        />
      </Head>
      <GlobalStyles />
      <Component {...pageProps} />
      {process.env.NEXT_PUBLIC_ENVIRONMENT === "development" ? (
        <LanguageOption />
      ) : (
        <></>
      )}
    </>
  );
};

export default MyApp;
